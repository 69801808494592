import React from "react";

import HeaderModule from "../../modules/header";
import FooterModule from "../../modules/footer";

import "./index.scss";
import "./responsive.scss";

import routesData from "./routes";

export default ({ className, children }) => {
	return (
		<div className="grid layout-container">
			<HeaderModule className={className} routes={routesData} />
			{children}
			<FooterModule />
		</div>
	);
};
