import React from "react";
import { Link } from "gatsby";

import NavAtom from "../../atoms/nav";

export default ({ routes }) => {
	const RoutesList = routes.map((route, index) => {
		return (
			<li key={index}>
				<Link
					to={route.path}
					className="scaled"
					activeClassName="active"
				>
					{route.name}
				</Link>
			</li>
		);
	});

	return <NavAtom>{RoutesList}</NavAtom>;
};
