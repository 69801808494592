import React from "react";
import { Link } from "gatsby";

import Logo from "../../../assets/logo-mono.svg";

import SocialModule from "../social";

import "./index.scss";
import "./responsive.scss";

export default () => {
	return (
		<footer id="footer">
			<span>
				<span className="copy">©</span> MrKirilov
			</span>
			<Link className="logo" to="/">
				<img alt="MrKirilov Logo" src={Logo} />
			</Link>
			<SocialModule />
		</footer>
	);
};
