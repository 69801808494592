import React from "react";

import "./index.scss";

export default ({ children }) => {
	return (
		<nav>
			<ul>{children}</ul>
		</nav>
	);
};
