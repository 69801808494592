import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default ({ page }) => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					title
					description
					keywords
					siteUrl
					image
					author
					email
				}
			}
		}
	`);

	const { title, description, keywords, image, author, email } =
		data.site.siteMetadata;

	let siteUrl = data.site.siteMetadata.siteUrl;

	if (siteUrl === "" && typeof window !== "undefined") {
		siteUrl = window.location.origin;
	}

	const pageTitle = page?.title ? `${page.title} | ${title}` : title;
	const pageDescription = page?.description || description;
	const pageKeywords = page?.keywords || keywords;
	const pageUrl = page?.path ? `${siteUrl}${page.path}` : siteUrl;
	const pageImage = page?.image ? new URL(page?.image, siteUrl) : false;

	return (
		<Helmet htmlAttributes={{ lang: "en" }}>
			<title>{pageTitle}</title>
			<link rel="canonical" href={pageUrl} />
			<meta name="description" content={pageDescription} />
			<meta name="keywords" content={pageKeywords} />
			{image && <meta name="image" content={pageImage} />}
			<meta name="robots" content="index, follow" />
			<meta name="theme-color" content="#CC527C" />
			<meta
				name="viewport"
				content="width=device-width, user-scalable=no"
			/>

			<meta property="og:url" content={pageUrl} />
			<meta property="og:type" content="page" />
			<meta property="og:title" content={pageTitle} />
			<meta property="og:site_name" content={title} />
			<meta property="og:description" content={pageDescription} />
			<meta property="og:keywords" content={pageKeywords} />
			{image && <meta property="og:image" content={pageImage} />}

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content={author} />
			<meta name="twitter:title" content={pageTitle} />
			<meta name="twitter:description" content={pageDescription} />
			{image && <meta name="twitter:image" content={pageImage} />}

			<script type="application/ld+json">
				{`
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						"url": ${pageUrl},
						"name": ${pageTitle},
						"author": ${author}
						"contactPoint": {
							"@type": "ContactPoint",
							"email": ${email},
						}
					}
				`}
			</script>
		</Helmet>
	);
};
