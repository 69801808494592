import React from "react";
import { Link } from "gatsby";
import "@animated-burgers/burger-squeeze/dist/styles.css";

import Logo from "../../../assets/logo-color.svg";
import MainNavElement from "../../elements/main-nav";

import "./index.scss";
import "./responsive.scss";

export default ({ className, routes }) => {
	return (
		<>
			<header id="header" className={className}>
				<Link className="logo" to="/">
					<img alt="MrKirilov Logo" src={Logo} />
				</Link>
				<MainNavElement routes={routes} />
			</header>
		</>
	);
};
