import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { FiMail, FiGithub, FiLinkedin } from "react-icons/fi";

import "./index.scss";

export default () => {
	return (
		<div className="social-container">
			<OutboundLink href="mailto:hi@mrkirilov.com">
				<FiMail aria-label="Mail" />
			</OutboundLink>
			<OutboundLink
				target="_blank"
				href="https://www.linkedin.com/in/georgi-desislavov-kirilov/"
			>
				<FiLinkedin aria-label="Linkedin" />
			</OutboundLink>
			<OutboundLink target="_blank" href="https://github.com/georgi2411">
				<FiGithub aria-label="Github" />
			</OutboundLink>
		</div>
	);
};
